import styled, { keyframes } from "styled-components";
import { color_dict } from "../utils/ColorDict";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${color_dict.backgroundColor};
  color: white;
  overflow-y: scroll;
`;

export const StepContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  position: relative;
  min-height: 0;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  padding: 20px;
  
  img {
    width: 100%;
    height: 100%;
    border-radius: 24px;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  margin: 0px 0 2px 0;
`;

export const SubTitle = styled.p`
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 16px;
  margin: 0 0 32px 0;
`;

export const PlansContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 400px;
  overflow: auto;
  position: relative;
`;

export const PlanCard = styled.div`
  background-color: #252525;
  border-radius: 12px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  border: 2px solid ${({ selected }) => (selected ? "#FFD700" : "transparent")};
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    background-color: #303030;
  }
`;

export const PlanHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

export const PlanHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const PlanName = styled.h3`
  font-size: 20px;
  font-weight: 600;
  margin: 0;
`;

export const PlanSubtitle = styled.p`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
`;

export const PlanPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Amount = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: #FFD700;
`;

export const Period = styled.span`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
`;

export const PlanFeatures = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const Feature = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;

  &:before {
    content: "✓";
    color: #FFD700;
  }
`;

export const SubscribeButton = styled.button`
  width: 100%;
  max-width: 400px;
  padding: 16px;
  border-radius: 8px;
  background-color: #FFD700;
  color: #1a1a1a;
  border: none;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 32px;
  margin-bottom: 32px;

  &:hover {
    transform: translateY(-2px);
    background-color: #FFEB3B;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  gap: 20px;
`;

export const Spinner = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

export const OverlayText = styled.div`
  color: white;
  font-size: 20px;
  text-align: center;
`;

export const OverlaySubText = styled.div`
  color: #999;
  font-size: 14px;
`;