export const plans = [
    {
        id: 'basic',
        name: 'Normie Plan',
        price: 4.99,
        period: '/ month',
        features: [
            '20 credits / month',
            'Enough for 4 Videos or 10 Morphs',
        ],
        gumroadId: 'basic_ltn_monthly'
    },
    {
        id: 'premium',
        name: 'Chad Plan',
        price: 9.99,
        period: '/ month',
        features: [
            '40 AI-generation credits / month',
            'Enough for 8 Videos or 20 Morphs',
        ],
        gumroadId: 'premium_chad_monthly'
    },
    {
        id: 'super',
        name: 'PSLGod Plan',
        price: 19.99,
        period: '/ month',
        features: [
            '100 AI-generation credits / month',
            'Enough for 20 Videos or 50 Morphs',
        ],
        gumroadId: 'super_psl_god_monthly'
    }
]; 