import React, { useState, useMemo, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import {
    Card,
    Header,
    UserInfo,
    Avatar,
    UserMeta,
    UserName,
    PostDate,
    MenuButton,
    ImageCarousel,
    CarouselDots,
    Dot,
    CarouselImage,
    GridContainer,
    GridImage
} from './BundleCard.styles';
import { FiMoreHorizontal, FiSend, FiTrash } from 'react-icons/fi';
import { FaBookmark, FaCross, FaRegBookmark } from 'react-icons/fa';
import LoadingThingie from '../common/LoadingThingie';
import { getBundleInfo, createPslGodBundleVideo, createVideoForSpecificMedia, deleteBundle } from '../../services/api';
import { transformBundle } from '../../utils/bundleTransforms';
import { useAsyncError, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from '../../features/dialog/dialogSlice';
import { current } from '@reduxjs/toolkit';
import { RxBorderWidth, RxCross1 } from 'react-icons/rx';
import LooxerButton from '../common/Button';
import { color_dict } from '../../utils/ColorDict';

const carouselImageWithText = (img_url, text, emojis) => {
    return (
        <div style={{
            // backgroundColor: 'red',
            width: '100%',
            height: '100%',

            position: 'relative'
        }}>
            <img style={{
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
            }}
                src={img_url}
            />

            <div style={{
                width: '100%',
                position: 'absolute',
                top: 0,
                left: 0,

                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                padding: '20px',
                boxSizing: 'border-box',

                fontFamily: 'Inter',

                fontSize: '40px',
                fontWeight: '900',

                // WebkitTextStroke: "3px black", // Stroke width and color

            }}>
                <div style={{
                    textShadow: `
      -2px -2px 0 black, 
      2px -2px 0 black, 
      -2px 2px 0 black, 
      2px 2px 0 black
    `,
                }}>
                    {text}
                </div>

                <div>
                    {emojis}
                </div>
            </div>


        </div>
    )
}


function getSecondsSince(dateString) {
    // Parse the input date string into a Date object
    const inputDate = new Date(dateString);

    // Get the current time as a Date object
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const differenceInMs = currentDate - inputDate;

    // Convert the difference to seconds
    const differenceInSeconds = Math.floor(differenceInMs / 1000);

    return differenceInSeconds;
}


const getCarouselElements = (images, bundleType, isProcessing, isSubscribed, bundleData) => {
    if (!images) {
        return [];
    }

    if (isProcessing) {

        const og_image = images.filter((item) => (item.proc_type === 'og'))[0].url;

        return [
            <div style={{
                width: '100%',
                height: '100%',

                position: 'relative',

            }}>
                <img src={og_image} style={{
                    height: '100%',
                    position: 'absolute',

                    top: '50%',
                    left: '50%',

                    transform: 'translateX(-50%) translateY(-50%)'
                }} />
            </div>
        ];
    }

    try {
        if (bundleType === 0) {

            const cooked_image = images.filter((item) => (item.proc_type === 'og_cropped'))[0].url;
            const prog1_image = images.filter((item) => (item.proc_type === 'ltn'))[0].url;
            if (isSubscribed) {
                return [
                    carouselImageWithText(cooked_image, "Swipe->"),
                    carouselImageWithText(prog1_image, "50% Potential")
                ];
            }
            else {
                return [
                    carouselImageWithText(cooked_image, "Swipe->"),
                    carouselImageWithText(prog1_image, "50% Potential"),
                    carouselImageWithText(prog1_image, "75% Potential"),
                ];
            }
        }
        if (bundleType === 1) {
            console.log('images: ', images);


            const cooked_image = images.filter((item) => (item.proc_type === 'og_cropped'))[0].url;
            const prog1_image = images.filter((item) => (item.proc_type === 'ltn'))[0].url;
            const prog2_image = images.filter((item) => (item.proc_type === 'htn'))[0].url;
            const prog3_image = images.filter((item) => (item.proc_type === 'chad'))[0].url;
            const model_image = images.filter((item) => (item.proc_type === 'model'))[0].url;
            const pslgod_image = images.filter((item) => (item.proc_type === 'psl_god'))[0].url;

            // const htn_image = images.filter((item) => (item.proc_type === 'src2src_cropped_brows_cheeks_ogbg'))[0].url;
            // const chad_image = images.filter((item) => (item.proc_type === 'src2src_cropped_brows_cheeks'))[0].url;
            // const psl_god_image = images.filter((item) => (item.proc_type === 'src2trg_cropped_brows_cheeks'))[0].url;

            const elements = [
                carouselImageWithText(cooked_image, "0%", ""),
                carouselImageWithText(prog1_image, "50%", ""),
                carouselImageWithText(prog2_image, "75%", ""),
                carouselImageWithText(prog3_image, "90%", ""),
                carouselImageWithText(model_image, "Model", "🤫🧏‍♂️"),
                carouselImageWithText(pslgod_image, "PSL GOD", "🤴🥶"),
            ];

            return elements;
        }
        else if (bundleType === 3) {
            console.log('bundletype 3 images: ', images);
            const psl_god_video = images.filter((item) => (item.proc_type === 'src2trg_cropped_brows_cheeks_vid'))[0].url;

            return [
                <div style={{
                    width: '100%',
                    height: '100%',

                    position: 'relative',

                }}>
                    <video src={psl_god_video} autoPlay loop muted playsInline style={{
                        height: '100%',
                        position: 'absolute',

                        top: '50%',
                        left: '50%',

                        transform: 'translateX(-50%) translateY(-50%)'
                    }} />

                </div>
            ];
        }
    } catch {
        return [];
    }
}

const ImageCarouselRon = ({
    handlers,
    images,
    currentImageIndex,
    isProcessing,
    bundleType,
    setLength,
    setShowCreateVideoButton,
    setCreateVideoTier,
    bundleData,
    isSubscribed,
    errorProcessing
}) => {
    // const [imageIndex, setImageIndex] = useState(0);
    const { credits } = useSelector(state => state.auth.user || { credits: 0 });
    const [progress, setProgress] = useState(0);
    const navigate = useNavigate();

    const elements = useMemo(() => getCarouselElements(
        images = images,
        bundleType = bundleType,
        isProcessing = isProcessing,
        isSubscribed = isSubscribed,
        bundleData = bundleData,
    ), [isProcessing, images, bundleData]);

    const time_to_wait_seconds = bundleType == 1 ? 180 : bundleType == 3 ? 180 : (bundleType === 0 ? 60 : 10000);

    useEffect(() => {
        setLength(elements.length);
    }, [elements]);

    useEffect(() => {
        let interval;

        if (isProcessing) {
            interval = setInterval(() => {
                const elapsedSeconds = getSecondsSince(bundleData.createdAt);
                const newProgress = 1 - (Math.max(
                    0,
                    Math.min(100000, time_to_wait_seconds - elapsedSeconds)
                ) / time_to_wait_seconds);

                setProgress(newProgress);
            }, 1000); // Update every second
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isProcessing, time_to_wait_seconds, bundleData]);

    return (
        <div style={{
            width: '100%',
            aspectRatio: 1,
            overflow: 'hidden',
            position: 'relative'
        }}
            {...handlers}
        >
            {
                elements.map((item, index) => (
                    <div style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: index < currentImageIndex ? '-100%' : (index == currentImageIndex ? '0' : '100%'),
                        transition: '0.2s all'
                    }} key={index}>

                        {
                            React.cloneElement(item, { key: index, bundledata: bundleData })
                        }

                        <div style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            backdropFilter: (isProcessing || (bundleType === 0 && (index > 1))) ? 'blur(8px)' : '',
                            WebkitBackdropFilter: (isProcessing || (bundleType === 0 && (index > 1))) ? 'blur(8px)' : '',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>

                            {
                                errorProcessing && (
                                    <div style={{
                                        backgroundColor: 'rgba(0,0,0,0.8)',
                                        padding: '20px',
                                        boxSizing: 'border-box',


                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}>
                                        <RxCross1 size="100px" color="red" style={{
                                            strokeWidth: '2px',
                                        }} />
                                        <div style={{ textAlign: 'center' }}>Error processing image, we've refunded your credits.</div>
                                        <div style={{
                                            maxWidth: '80%',
                                            color: 'rgb(200,200,200)',
                                            fontSize: '12px',
                                            textAlign: 'center',
                                        }}>Make sure the image you use is of you looking straight at the camera, with a neutral face and good lighting.</div>
                                    </div>
                                )
                            }

                            {!errorProcessing && isProcessing && (
                                <>

                                    <LoadingThingie />
                                    <div style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',

                                        transform: 'translateX(-50%) translateY(-50%)'
                                    }}>
                                        {Math.round(progress * 100)}%
                                    </div>

                                </>
                            )}

                            {
                                (!errorProcessing && !isProcessing && (bundleType === 0) && (index > 1)) && (
                                    <LooxerButton
                                        text="See your full morph"
                                        active={true}
                                        onClick={() => {
                                            if (credits > 2) {
                                                navigate('/upload');
                                            }
                                            else {
                                                navigate('/more-credits');
                                            }


                                        }}

                                    />
                                )
                            }

                        </div>
                    </div>
                ))
            }

        </div>
    );
};


const IconsRowButton = ({ text, type, onClick, isLoading, disabled, currentImageIndex }) => {
    return (
        <div
            onClick={(!disabled) && onClick}
            style={{
                backgroundColor: type === 'report' ? 'rgb(230, 50, 50)' : 'rgb(100,100,100)',
                fontSize: '12px',
                padding: '5px 7px',
                borderRadius: '7px',
                cursor: disabled ? 'default' : 'pointer',
                opacity: disabled ? 0.6 : 1,
                display: 'flex',
                alignItems: 'center',
                gap: '5px'
            }}
        >
            {text}
            {isLoading && (

                <LoadingThingie style={{
                    width: '12px',
                    height: '12px',
                    RxBorderWidth: '2px',

                }} />

            )}
        </div>
    )
}

const IconsRow = ({ bundleId, isProcessing, isSubscribed, includeButton, setReloadBundles, images, currentImageIndex }) => {
    const navigate = useNavigate();
    const [isCreatingPsl, setIsCreatingPsl] = useState(false);
    const { credits } = useSelector(state => state.auth.user || { credits: 0 });
    const dispatch = useDispatch();

    const handleCreatePslVideo = async (currentImageIndex, images) => {
        if (currentImageIndex < 4 || isCreatingPsl || isProcessing) return;

        const UPLOAD_COST = 5; // Credits cost for upload
        const remainingCredits = credits - UPLOAD_COST;

        if (credits < UPLOAD_COST) {
            dispatch(openDialog({
                title: 'Insufficient Credits',
                message: `You need ${UPLOAD_COST} credits to create a video. You currently have ${credits} credits.`,
                confirmText: 'Get Credits',
                cancelText: 'Cancel',
                onConfirm: () => {
                    navigate('/more-credits');
                }
            }));
            return;
        }


        dispatch(openDialog({
            title: 'Confirm Creation',
            message: `Creating a video costs ${UPLOAD_COST} credits. You will have ${remainingCredits} credits remaining.`,
            confirmText: 'Upload',
            cancelText: 'Cancel',
            onConfirm: async () => {
                let image_id = null;

                if (currentImageIndex === 4) {
                    image_id = images.filter((item) => (item.proc_type === 'model'))[0].id;
                }
                else {
                    image_id = images.filter((item) => (item.proc_type === 'psl_god'))[0].id;
                }

                setIsCreatingPsl(true);
                try {
                    const result = await createVideoForSpecificMedia(image_id);
                    // You might want to handle the successful creation here
                    // For example, showing a success message or updating the UI
                } catch (error) {
                    console.error('Failed to create PSL God bundle video:', error);
                    // You might want to show an error message to the user
                } finally {
                    setIsCreatingPsl(false);
                    setTimeout(() => setReloadBundles(true), 500);
                }
            }
        }));
    };

    return (
        <div style={{
            // backgroundColor:'red',
            height: '40px',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '16px',
        }}>
            <FaRegBookmark style={{
                color: 'white',
            }}
                size="24px"
            />
            <FiSend style={{
                color: 'white',
            }}
                size="24px"
            />

            <div style={{
                flex: '1 0 0%',
                height: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '10px',
            }}>
                {
                    includeButton && (
                        <IconsRowButton
                            text={["Create Video", "Create Video", "Create Video", "Create Video", "Create Chad Video", "Create PSL Video"][currentImageIndex]}
                            onClick={() => {
                                handleCreatePslVideo(currentImageIndex, images);
                            }}
                            isLoading={isCreatingPsl}
                            disabled={isCreatingPsl || isProcessing || currentImageIndex < 4}
                            currentImageIndex={currentImageIndex}
                        />
                    )
                }

            </div>
        </div>
    )
}

const Description = ({ user, text, hashtags, bundleType, isSubscribed }) => {
    const navigate = useNavigate();

    return (
        <div style={{
            // backgroundColor:'red',
            // height: '30px',
            width: '100%',

            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            flexWrap: 'wrap',

            gap: '4px',

            fontSize: '14px',
            color: 'white'
        }}>

            {
                (bundleType === 1 || bundleType === 2) && (
                    <div>
                        <span style={{ fontWeight: 'bold' }}>{user}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                        {text}
                    </div>
                )
            }

            {
                (bundleType === 0 && isSubscribed) && (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                    }}>
                        <div style={{
                            fontWeight: '700',
                            marginBottom: '5px',
                        }}>
                            Welcome to looxer.ai Premium
                        </div>
                        <div style={{
                            fontSize: '12px',
                        }}>
                            Start your jorney by clicking the camera button below.
                        </div>
                    </div>
                )
            }

            {
                (false && bundleType === 0 && !isSubscribed) && (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                    }}>
                        <div style={{
                            fontWeight: '700',
                            marginBottom: '5px',
                        }}>
                            Thank you for trying looxer.ai!
                        </div>
                        <div style={{
                            fontSize: '12px',
                        }}>
                            We hope you enjoyed the free morph.
                            Unfortunately, the image processing is very pricey for us. So we have to charge a small fee for our full services.
                            We hope you understand.
                            <br /><br />
                            Here is what you get with Looxer.ai - Premium:
                        </div>

                        <video autoPlay
                            loop
                            muted
                            playsInline

                            style={{
                                width: '60%',
                                alignSelf: 'center',
                                marginTop: '5px',
                                marginBottom: '20px',

                                boxShadow: 'rgba(0, 0, 0, 0.56) 0px 22px 70px 4px',
                                // height: '100%',
                                // transform:'translateY(5px)',
                            }}
                            alt="test2"
                        >
                            <source src="/images/demo.mp4" />
                        </video>

                        <div style={{
                            fontSize: '12px',
                        }}>
                            If you every get results you're unsatisfied with, you get your credits back, that's our guarantee.
                        </div>

                        <LooxerButton
                            active={true}
                            text="I'm interested in Premium Access"
                            onClick={() => (navigate('/subscribe'))}
                            style={{
                                alignSelf: 'center',
                                padding: '10px 15px',
                                fontSize: '14px',
                                marginTop: '10px',
                            }}
                        />
                    </div>
                )
            }



            {/* {
                hashtags.map((item,index)=>(
                    <div key={index}>
                        {item}
                    </div>
                ))
            } */}
        </div>
    )
}

function formatDateString(dateString) {

    const now = new Date();
    const inputDate = new Date(dateString);

    // Calculate the time difference in milliseconds
    const timeDifference = now - inputDate;

    // Helper to format ordinal suffix for the day
    const getOrdinalSuffix = (day) => {
        if (day > 3 && day < 21) return 'th'; // Special case for teens
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    // Case 1: "Just now" (within 60 seconds)
    if (timeDifference < 60 * 5000) {
        return "Just now";
    }


    // Case 2: "X hours ago" (within 24 hours)
    const minutesDifference = Math.floor(timeDifference / (60 * 1000));
    if (minutesDifference < 60) {
        return `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`;
    }


    // Case 2: "X hours ago" (within 24 hours)
    const hoursDifference = Math.floor(timeDifference / (60 * 60 * 1000));
    if (hoursDifference < 24) {
        return `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
    }

    // Format month and day
    const options = { month: 'long', day: 'numeric' };
    const formattedDate = inputDate.toLocaleDateString('en-US', options);

    // Case 3: "December 10th" (within the same year)
    if (inputDate.getFullYear() === now.getFullYear()) {
        const day = inputDate.getDate();
        const ordinalSuffix = getOrdinalSuffix(day);
        return `${formattedDate}${ordinalSuffix}`;
    }

    // Case 4: "January 13th, 2022" (different year)
    const fullDateOptions = { month: 'long', day: 'numeric', year: 'numeric' };
    return inputDate.toLocaleDateString('en-US', fullDateOptions);
}

const BundleCard = ({ bundle, setReloadBundles, isSubscribed }) => {
    const navigate = useNavigate();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [bundleData, setBundleData] = useState(bundle);
    const [length, setLength] = useState(0);
    const dateString = useMemo(() => formatDateString(bundleData.createdAt), [bundleData.createdAt]);
    const [showCreateVideoButton, setShowCreateVideoButton] = useState(false);
    const [createVideoTier, setCreateVideoTier] = useState("");
    const [showDelete, setShowDelete] = useState(false);
    const [deleteProcessing, setDeleteProcessing] = useState(false);

    // Add polling logic using useEffect
    useEffect(() => {
        let intervalId;

        const pollBundleStatus = async () => {
            try {
                console.log('Polling bundle status for', bundleData);
                const updatedBundleRaw = await getBundleInfo(bundleData.id);
                const updatedBundle = transformBundle(updatedBundleRaw);
                setBundleData(updatedBundle);

                // If bundle is no longer processing, clear the interval
                if (!updatedBundle.is_processing) {
                    clearInterval(intervalId);
                }
            } catch (error) {
                console.error('Error polling bundle status:', error);
                // Clear interval on error to prevent continuous failed requests
                clearInterval(intervalId);
            }
        };

        // Only start polling if bundle is processing
        if (bundleData.is_processing) {
            // Initial check
            pollBundleStatus();
            // Set up interval for subsequent checks
            intervalId = setInterval(pollBundleStatus, 5000);
        }

        // Cleanup function to clear interval when component unmounts
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [bundleData.id, bundleData.is_processing]);

    // Update all bundle references to use bundleData instead of bundle
    const isProcessing = bundleData.is_processing;

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            if (isProcessing) return;

            if (currentImageIndex < length - 1) {
                setCurrentImageIndex(prev => prev + 1);
            }
        },
        onSwipedRight: () => {
            if (isProcessing) return;

            if (currentImageIndex > 0) {
                setCurrentImageIndex(prev => prev - 1);
            }
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    return (
        <Card>
            <Header>
                <UserInfo>
                    <Avatar src={bundleData.user.profileImage} alt={bundleData.user.name} />
                    <UserMeta>
                        <UserName>{bundleData.user.name}</UserName>
                        <div style={{
                            color: 'rgb(180, 180, 180)',
                            fontSize: '12px',
                        }}><span style={{ fontWeight: '600' }}>Private</span> - Only visible to you</div>
                        {/* <PostDate>{new Date(bundle.createdAt).toLocaleDateString()}</PostDate> */}
                    </UserMeta>
                </UserInfo>
                <MenuButton>
                    <FiTrash size={20} onClick={() => {
                        if (!deleteProcessing) {
                            setShowDelete((sd) => (!sd));
                        }
                    }} />
                </MenuButton>

                {
                    showDelete && (
                        <div style={{
                            backgroundColor: color_dict.backgroundColor,
                            width: '200px',
                            height: '120px',

                            position: 'absolute',
                            bottom: '0px',
                            right: '10px',

                            transform: 'translateY(99%)',
                            zIndex: 10,
                            boxShadow: 'rgb(0, 0, 0) 0px 20px 30px -10px',

                            borderRadius: '10px',

                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center',

                            textAlign: 'center',
                            padding: '10px',
                            boxSizing: 'border-box',
                        }}>
                            <div style={{
                                fontSize: '14px',
                            }}>
                                This post is private and only you can see it.

                                <div style={{
                                    fontSize: '10px',
                                    color: 'rgb(180,180,180)'
                                }}>
                                    Are you sure you want to delete it?
                                </div>
                            </div>

                            <LooxerButton
                                text={deleteProcessing ? "Deleting.." : "Yes, Delete"}
                                style={{
                                    fontSize: '13px',
                                    padding: '7px 15px',
                                    backgroundColor: 'rgb(200,0,0)',
                                }}
                                active={!deleteProcessing}
                                onClick={async () => {
                                    setDeleteProcessing(true);
                                    const result = await deleteBundle(bundle.id);
                                    navigate('/get-started');
                                    setDeleteProcessing(false);
                                }}

                            />
                        </div>
                    )
                }
            </Header>

            <ImageCarouselRon
                handlers={handlers}
                images={bundleData.images}
                currentImageIndex={currentImageIndex}
                isProcessing={isProcessing}
                bundleType={bundleData.type}
                setLength={setLength}
                setShowCreateVideoButton={setShowCreateVideoButton}
                setCreateVideoTier={setCreateVideoTier}
                bundleData={bundleData}
                isSubscribed={isSubscribed}
                errorProcessing={bundleData.error_processing}
            />

            {
                (length > 1) &&
                (<CarouselDots>
                    {[...Array(length)].map((_, index) => (
                        <Dot
                            key={index}
                            $active={index === currentImageIndex}
                            onClick={() => setCurrentImageIndex(index)}
                        />
                    ))}
                </CarouselDots>)
            }


            <div style={{
                width: '100%',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'stretch',

                padding: '5px 20px',
                boxSizing: 'border-box',

            }}>
                {
                    (bundleData.type !== 0) && (<IconsRow
                        bundleId={bundleData.id}
                        isProcessing={isProcessing}
                        includeButton={bundleData.type === 1 && !bundleData.error_processing && !isProcessing}
                        setReloadBundles={setReloadBundles}
                        showCreateVideoButton={showCreateVideoButton}
                        createVideoTier={createVideoTier}
                        currentImageIndex={currentImageIndex}
                        images={bundleData.images}
                        isSubscribed={isSubscribed}
                    />)
                }

                <Description
                    user={bundleData.user.name}
                    text=
                    {bundleData.error_processing ? "Error processing bundle 😭 We refunded your credits" : (bundleData.type == 1 ? "Born to mog." : "Imagine standing next to this guy😳")}
                    bundleType={bundleData.type}
                    isSubscribed={isSubscribed}
                />
                <div style={{
                    color: 'rgb(100,100,100)',
                    fontSize: '12px',
                    marginTop: '8px',
                }}>{dateString}</div>
            </div>
        </Card>
    );
};

export default BundleCard; 