import React, { useEffect, useState } from 'react';
import {
    Container,
    TopBar,
    TitleTypography,
    LogoContainer,
    IconButton,
    BottomBar,
    FloatingActionButton,
    FeedContainer,
} from './DashboardScreen.styles';
import { RxHamburgerMenu } from 'react-icons/rx';
import { FaUser } from 'react-icons/fa';
import { IoCamera } from 'react-icons/io5';
import Feed from '../components/Feed/Feed';
import { useNavigate } from 'react-router-dom';
import { getUserFeed, getMediaUrl } from '../services/api';
import { color_dict } from '../utils/ColorDict';
import { transformBundle } from '../utils/bundleTransforms';
import WebappContainer from '../components/common/WebappContainer';
import LooxerButton from '../components/common/Button';

const DashboardScreen = ({ isSubscribed, setReloadUser }) => {
    const navigate = useNavigate();
    const [bundles, setBundles] = useState([]);
    const [reloadBundles, setReloadBundles] = useState(true);

    useEffect(() => {
        setReloadUser(true);
    }, [])

    useEffect(() => {
        const fetchBundles = async () => {

            try {
                const response = await getUserFeed();
                if (response.success) {
                    // Transform the API response using the utility function
                    const transformedBundles = response.bundles.map(transformBundle);

                    console.log('bundles: ', response.bundles)
                    console.log('transformedBundles: ', transformedBundles)

                    setBundles(transformedBundles);
                }
            } catch (error) {
                console.error('Error fetching bundles:', error);
                // You might want to show an error message to the user here
            }
        };

        if (reloadBundles) {
            setReloadBundles(false);
            fetchBundles();
        }
    }, [reloadBundles]);

    const handleCameraClick = () => {
        navigate('/upload');
    };

    const handleUserClick = () => {
        navigate('/user-profile');
    };

    return (
        <WebappContainer style={{

        }}>
            <TopBar>
                <div style={{
                    height: '80%',
                    aspectRatio: 2,
                    position: 'relative',
                    overflow: 'hidden',
                }}>
                    <video autoPlay
                        loop
                        muted
                        playsInline

                        style={{
                            // width: '100px',
                            height: '100%',
                            // transform:'translateY(5px)',
                        }}
                    >
                        <source src="/images/alpha_logo.webm" />
                    </video>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '20px',
                }}>
                    <div style={{
                        fontSize: '8px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '5px',
                        textAlign: 'center',
                        color: 'rgb(170,170,170)'
                    }}>
                        Encountered an issue? <br />
                        Reach us at: <span style={{ color: 'white' }}>looxer.ai@gmail.com</span>
                    </div>
                    <IconButton onClick={handleUserClick}>
                        <FaUser size={22} />
                    </IconButton>
                </div>
            </TopBar>

            <FeedContainer>
                <Feed
                    bundles={bundles}
                    setReloadBundles={setReloadBundles}
                    isSubscribed={isSubscribed} />
            </FeedContainer>

            <FloatingActionButton onClick={handleCameraClick}>
                <IoCamera size={28} />
            </FloatingActionButton>

            <div style={{
                position: 'absolute',
                bottom: 0,
                height: '45px',
                width: '100%',
                backgroundColor: '#252525',
                borderTop: '5px solid rgb(60, 60, 60)',
                transform: 'translateY(2px)',
                // borderImage: 'linear-gradient(to right, rgb(222, 46, 85) 10%, rgb(44, 82, 145) 25%, rgb(10, 159, 101) 35%, rgb(244, 191, 17) 60%, rgb(224, 109, 62) 75%, rgb(116, 64, 128) 90%)',
                borderImageSlice: 1,
            }}>

            </div>
            {/* <BottomBar>
                
            </BottomBar> */}
        </WebappContainer >
    );
};

export default DashboardScreen; 