import React from 'react';
import styled from 'styled-components';
import { color_dict } from '../../utils/ColorDict';

export const PlanCard = styled.div`
    background-color: #252525;
    border-radius: 12px;
    padding: 20px 32px;
    padding-bottom: 0px;
    cursor: ${props => props.$clickable ? 'pointer' : 'default'};
    border: 2px solid ${props => props.selected ? color_dict.logo_pink_exciting : 'transparent'};
    transition: all 0.2s ease;
    
    &:hover {
        transform: ${props => props.$clickable ? 'translateY(-2px)' : 'none'};
    }
`;

export const PlanHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
`;

export const PlanHeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const PlanName = styled.h3`
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    color: ${color_dict.logo_pink_exciting};
`;

export const PlanSubtitle = styled.p`
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
`;

export const PlanPrice = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const Amount = styled.span`
    font-size: 20px;
    font-weight: 700;
    color: ${color_dict.logo_pink_exciting};
`;

export const Period = styled.span`
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
`;

export const PlanFeatures = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    transform: translateY(-10px);
`;

export const Feature = styled.li`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;

    &:before {
        content: "•";
        color: ${color_dict.logo_pink_exciting};
    }
`;

const SubscriptionPlanCard = ({
    plan,
    selected,
    onClick,
    showStatus = false,
    isClickable = true,
    isActive = false
}) => {
    return (
        <PlanCard
            selected={selected}
            onClick={onClick}
            $clickable={isClickable}
        >
            <PlanHeader>
                <PlanHeaderContent>
                    <PlanName>{plan.name}</PlanName>
                </PlanHeaderContent>
                {isActive ? (
                    <PlanPrice>
                        <Amount>Active</Amount>
                    </PlanPrice>
                ) : (
                    <PlanPrice>
                        <Amount>${plan.price}</Amount>
                        <Period>{plan.period}</Period>
                    </PlanPrice>
                )}
            </PlanHeader>
            <PlanFeatures>
                {plan.features.map((feature, index) => (
                    <Feature key={index}>{feature}</Feature>
                ))}
                {showStatus && plan.status && (
                    <Feature>Status: {plan.status}</Feature>
                )}
                {showStatus && plan.nextBilling && (
                    <Feature>Next billing: {plan.nextBilling}</Feature>
                )}
            </PlanFeatures>
        </PlanCard>
    );
};

export default SubscriptionPlanCard; 