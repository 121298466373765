import React, { useState, useRef, useEffect } from "react";
import {
    Container,
    StepContainer,
    NextButton,
    LogoContainer,
    TopBar,
    MotivationalText,
    CameraContainer,
    CameraWindow,
    CaptureButton,
    UploadButton,
    SubTitle,
    ButtonContainer,
} from "./GetStartedScreen.styles";
import { useNavigate } from 'react-router-dom';
import { uploadPhoto } from '../services/api';
import WebappContainer from '../components/common/WebappContainer';
import LooxerButton from "../components/common/Button";
import { color_dict } from "../utils/ColorDict";
import { FaCamera, FaUpload } from "react-icons/fa";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import styled from 'styled-components';

const LOADING_MESSAGES = [
];

const HeadGuideOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 38%;
  aspect-ratio: 3/4;
  border: 2px dashed rgba(255, 255, 255, 0.7);
  border-radius: 50% 50% 50% 50% / 55% 55% 45% 45%;  // creates a more symmetrical head shape
  pointer-events: none;  // allows clicks to pass through
  z-index: 1000;
`;

const GetStartedScreen = () => {
    const navigate = useNavigate();
    const [imageSource, setImageSource] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const videoRef = useRef(null);
    const streamRef = useRef(null);
    const fileInputRef = useRef(null);
    const [isCameraReady, setIsCameraReady] = useState(false);

    const startCamera = () => {
        setTimeout(async () => {
            try {
                videoRef.current.setAttribute('autoplay', '');
                videoRef.current.setAttribute('muted', '');
                videoRef.current.setAttribute('playsInline', '');

                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                videoRef.current.srcObject = stream;
                streamRef.current = stream;
            } catch (err) {
                console.error("Error accessing camera:", err);
            }
        }, 200);
    };

    const stopCamera = () => {
        if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
        }
    };

    const capturePhoto = () => {
        const video = videoRef.current;
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0);
        const image = canvas.toDataURL('image/jpeg');
        setImageSource({
            data: image,
            fromCamera: true
        });
        stopCamera();
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSource({
                    data: reader.result,
                    fromCamera: false
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleReadyClick = async () => {
        setIsCameraReady(true);
        startCamera();
    };

    useEffect(() => {
        let messageInterval;
        if (isLoading) {
            setCurrentMessageIndex(Math.floor(Math.random() * LOADING_MESSAGES.length));
            messageInterval = setInterval(() => {
                setCurrentMessageIndex(current => {
                    const randomIndex = Math.floor(Math.random() * LOADING_MESSAGES.length);
                    return randomIndex;
                });
            }, Math.random() * (8000 - 4000) + 4000); // Random interval between 4-8 seconds
        }
        return () => clearInterval(messageInterval);
    }, [isLoading]);

    const handleSubmit = async () => {
        if (imageSource) {
            setIsLoading(true);
            try {
                const response = await uploadPhoto(imageSource.data);
                console.log(response);

                if (response.success) {
                    const delay = 5000;
                    setTimeout(() => {
                        navigate('/');
                    }, delay);
                }
            } catch (error) {
                console.error('Error uploading image:', error);
                setIsLoading(false);
                // TODO: Show error message to user
            }
        }
    };

    return (
        <WebappContainer>
            <TopBar>
                <LogoContainer>
                    <img src="/images/looxer-short.png" alt="Logo" />
                </LogoContainer>
            </TopBar>
            <StepContainer>
                <MotivationalText>Let's take your first photo</MotivationalText>
                <SubTitle>
                    Ensure good lighting and position your face
                </SubTitle>

                <CameraContainer style={{
                    border: (!isCameraReady && !imageSource) ? `2px solid ${color_dict.logo_purple}` : 'none'
                }}>
                    {imageSource ? (
                        <>
                            <img
                                src={imageSource.data}
                                alt="Captured"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    transform: imageSource.fromCamera ? 'scaleX(-1)' : 'none',
                                    filter: isLoading ? 'blur(10px)' : 'none',
                                    transition: 'filter 0.3s ease'
                                }}
                            />
                            {isLoading && (
                                <div style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: '20px'
                                }}>
                                    <div style={{
                                        width: '50px',
                                        height: '50px',
                                        border: `5px solid ${color_dict.pink_pale_background}`,
                                        borderTop: `5px solid ${color_dict.logo_pink}`,
                                        borderRadius: '50%',
                                        animation: 'spin 1s linear infinite',
                                    }} />
                                </div>
                            )}
                        </>
                    ) : isCameraReady ? (
                        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                            <CameraWindow ref={videoRef} autoPlay playsInline />
                            <HeadGuideOverlay />
                        </div>
                    ) : (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            gap: '20px'
                        }}>
                            <SubTitle style={{
                                textAlign: 'center',
                                color: "white"
                            }}>
                                We'll need access to your camera.
                            </SubTitle>
                            <LooxerButton
                                onClick={handleReadyClick}
                                text="I'm ready"
                                active={true}
                                color={color_dict.logo_purple}
                            />
                        </div>
                    )}
                </CameraContainer>

                <ButtonContainer>
                    {!imageSource ? (
                        isCameraReady ? (
                            <>
                                <LooxerButton
                                    onClick={capturePhoto}
                                    text="Take Photo"
                                    active={true}
                                    startIcon={<FaCamera />}
                                />
                                <LooxerButton
                                    onClick={handleUploadClick}
                                    text="Upload Photo"
                                    active={true}
                                    secondary
                                    startIcon={<FaUpload />}
                                />
                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileUpload}
                                    style={{ display: 'none' }}
                                />
                            </>
                        ) : null
                    ) : (
                        <>
                            <LooxerButton
                                onClick={() => {
                                    setImageSource(null);
                                    startCamera();
                                }}
                                text="Retake"
                                active={!isLoading}
                                secondary
                                startIcon={<IoChevronBack />}
                            />
                            <LooxerButton
                                onClick={handleSubmit}
                                text="Continue"
                                active={!isLoading}
                                endIcon={<IoChevronForward />}
                            />
                        </>
                    )}
                </ButtonContainer>
                <div style={{ flex: 1 }}>&nbsp;</div>
            </StepContainer>
            <style>
                {`
                    @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                    @keyframes fadeInOut {
                        0% { opacity: 0; transform: translateY(10px); }
                        20% { opacity: 1; transform: translateY(0); }
                        80% { opacity: 1; transform: translateY(0); }
                        100% { opacity: 0; transform: translateY(-10px); }
                    }
                `}
            </style>
        </WebappContainer>
    );
};

export default GetStartedScreen; 