import { useState, useEffect } from "react";
import { color_dict } from "../../utils/ColorDict";

const WebappContainer = ({ children, style }) => {

    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

    useEffect(() => {
        const viewport = window.visualViewport;

        function handleResize() {
            setViewportHeight(viewport.height);
        }

        function handleScroll() {
            // Immediately scroll back to the top whenever a scroll happens
            window.scrollTo(0, 0);
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 50);
        }

        viewport.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        // Cleanup event listeners on unmount
        return () => {
            viewport.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <div
            style={{
                backgroundColor: color_dict.backgroundColor,
                width: '100vw',
                height: `${viewportHeight}px`,
                // height: '100%',
                fontFamily: 'Plus Jakarta Sans',
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
                minWidth: 1,
                minHeight: 1,
                overflow: 'hidden',
                position: 'absolute',
                right: 0,
                top: 0,
                ...style
            }}
        >
            {children}
        </div>
    )
}

export default WebappContainer;