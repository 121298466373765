import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    StepContainer,
    LogoContainer,
    Title,
    SubTitle,
    PlansContainer,
} from './SubscriptionScreen.styles';
import { selectCurrentUser } from '../features/auth/authSlice';
import { useSelector } from 'react-redux';
import SubscriptionPlanCard from '../components/common/PlanCard';
import { plans } from '../constants/plans';
import { getHasSubscription } from '../services/api';
import { color_dict } from '../utils/ColorDict';
import LooxerButton from '../components/common/Button';
import { IoClose, IoRocketSharp } from "react-icons/io5";
import WebappContainer from '../components/common/WebappContainer';

const SubscriptionScreen = ({setReloadUser}) => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isWaitingForActivation, setIsWaitingForActivation] = useState(false);
    const [randomTagline, setRandomTagline] = useState(null);
    const [subscriptionActivated, setSubscriptionActivated] = useState(false);

    const randomTaglines = [
        "The image processing is very expensive. Which is why we have to charge for this service, hope you understand",
        "Your morph bundle is ready. You'll get this one for free after choosing a plan below.",
        "Our platform is premium-access only. Choose a plan below, you can cancel at any time."
    ]

    const user = useSelector(selectCurrentUser) || {};
    const navigate = useNavigate();

    useEffect(() => {
        if (user.subscription_id) {
            window.location.href = '/'; // Required for the /me endpoint to reload + update user credits count in UI
        }
    }, [user]);

    useEffect(() => {
        const newRandomNumber = Math.floor(Math.random() * 3);
        setRandomTagline(newRandomNumber);
    }, [])

    useEffect(() => {
        let interval;
        if (isWaitingForActivation) {
            interval = setInterval(async () => {
                try {
                    const response = await getHasSubscription();
                    if (response.has_subscription) {
                        setReloadUser(true);
                        setTimeout(()=>navigate('/'), 1000);
                    }
                } catch (error) {
                    console.error('Error checking subscription:', error);
                }
            }, 5000);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isWaitingForActivation, navigate]);

    const handleSubscribe = () => {
        if (!selectedPlan) return;
        const planData = plans.find(plan => plan.id === selectedPlan);
        setIsWaitingForActivation(true);
        window.open(`https://looxer.gumroad.com/l/${planData.gumroadId}`, '_blank');
    };

    const selectedPlanData = plans.find(plan => plan.id === selectedPlan);

    return (
        <WebappContainer style={{
            backgroundColor: color_dict.backgroundColor,
        }}>

            <StepContainer style={{
                background: `linear-gradient(180deg, ${color_dict.lighterBackgroundColor} 0%, ${color_dict.backgroundColor} 100%)`,
                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                backdropFilter: 'blur(5px)',
                border: '1px solid rgba(255, 255, 255, 0.05)',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                position: 'relative'
            }}>
                <div style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                }}>
                    <IoClose color="rgb(150,150,150)" size="26px" onClick={()=>navigate('/')}/>
                </div>

                <video autoPlay
                    loop
                    muted
                    playsInline

                    style={{
                        width: '150px',
                        marginTop:'10px',
                        marginBottom: '10px',
                    }}
                >
                    <source src="/images/alpha_logo.webm" />
                </video>

                <div style={{
                    flex: 1,
                    
                    padding: '0 20px',
                    marginBottom: '100px',

                    minHeight:1,

                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center',

                }}>
                    <Title style={{
                        color: 'white',
                        fontSize: '20px',
                        fontWeight: '600',
                        textShadow: '0 2px 4px rgba(0,0,0,0.2)',
                    }}>
                        Become a Premium
                    </Title>

                    <SubTitle style={{
                        // backgroundColor:'red',
                        color: 'rgba(255,255,255,0.8)',
                        marginBottom: '16px',
                    }}>
                        {randomTaglines[randomTagline]}
                    </SubTitle>

                    <div style={{
                        flex: '1 0 0%',

                        width: '100%',
                        maxWidth: '400px',
                        position: 'relative',

                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        
                        overflowY: 'scroll',

                        
                        padding:'15px',
                        boxSizing:'border-box',
                    }}>
                        {plans.map((plan) => (
                            <SubscriptionPlanCard
                                key={plan.id}
                                plan={plan}
                                selected={selectedPlan === plan.id}
                                onClick={() => setSelectedPlan(plan.id)}
                            />
                        ))}
                    </div>
                </div>

                <div style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    padding: '20px',
                    backgroundColor: color_dict.backgroundColor,
                    borderTop: `1px solid ${color_dict.lighterBackgroundColor}`,
                    zIndex: 10
                }}>
                    <LooxerButton
                        onClick={handleSubscribe}
                        text="Start My Transformation"
                        active={!!selectedPlan}
                        startIcon={<IoRocketSharp />}
                        color={color_dict.logo_pink}
                    />
                </div>

                {isWaitingForActivation && (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(26, 26, 26, 0.95)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '20px',
                        zIndex: 1000,
                    }}>
                        <div style={{
                            width: '50px',
                            height: '50px',
                            border: `4px solid ${color_dict.pink_pale_background}`,
                            borderTop: `4px solid ${color_dict.logo_pink}`,
                            borderRadius: '50%',
                            animation: 'spin 1s linear infinite',
                        }} />
                        
                        <div style={{
                            color: 'white',
                            fontSize: '20px',
                            fontWeight: '600',
                        }}>
                            Waiting for subscription activation...
                        </div>
                        <div style={{
                            width:'80%',
                            color: 'rgba(255,255,255,0.6)',
                            fontSize: '16px',
                            textAlign:'center',
                        }}>
                            Refresh this page after purchasing the subscription on gumroad
                        </div>
                    </div>
                )}
            </StepContainer>
            <style>
                {`
                    @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                `}
            </style>
        </WebappContainer>
    );
};

export default SubscriptionScreen; 