import { store } from '../app/store';
import { setApiError, setConnectivityError } from '../features/error/errorSlice';

export const handleApiError = (error, endpoint) => {
    const errorMessage = error.response?.message || error.message || 'An unexpected error occurred';
    store.dispatch(setApiError({ message: errorMessage, endpoint }));
};

export const handleConnectivityError = () => {
    store.dispatch(setConnectivityError());
};

const isConnectivityError = (error) => {
    // Check for network-level errors (server unreachable)
    if (error.name === 'TypeError' && (
        error.message.includes('Failed to fetch') ||
        error.message.includes('Network request failed') ||
        error.message.includes('Network error')
    )) {
        return true;
    }

    // Check for server error status codes (500+)
    if (error.response && error.response.status >= 500) {
        return true;
    }

    // Not a connectivity error if we got a response, even if it indicates an error
    return false;
};

const apiFetch = async (endpoint, options = {}) => {
    const { suppressError, suppressConnectivityError, ...fetchOptions } = options;

    try {
        const response = await fetch(`${API_BASE_URL}${endpoint}`, {
            ...fetchOptions,
            headers: {
                ...fetchOptions.headers,
                'Content-Type': 'application/json',
                'ngrok-skip-browser-warning': 'yes',
            },
        });

        if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData.message || `Request failed with status ${response.status}`);
        }

        return response;
    } catch (error) {
        if (isConnectivityError(error) && !suppressConnectivityError) {
            handleConnectivityError();
            throw new Error('Network error');
        }
        if (!isConnectivityError(error) && !suppressError) {
            handleApiError(error, endpoint);
        }

        throw error;
    }
};

const getRelativeApiUrl = () => {
    const hostname = window.location.hostname;
    if (hostname == 'looxer.ai') {
        return 'https://api.looxer.ai';
    }
    if (hostname == 'www.looxer.ai') {
        return 'https://api.looxer.ai';
    }
    if (hostname == 'c047-2a06-c701-4d80-df00-b106-c3ff-24e0-1c5d.ngrok-free.app') {
        return 'https://08e9-2a06-c701-4d80-df00-b106-c3ff-24e0-1c5d.ngrok-free.app';
    }
    return `http://${hostname}:5000`;
}

const API_BASE_URL = getRelativeApiUrl();

export const authenticateWithGoogle = async (credential) => {
    const response = await apiFetch('/auth/google', {
        method: 'POST',
        body: JSON.stringify({ token: credential })
    });

    const data = await response.json();

    if (data.success) {
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('user', JSON.stringify(data.user));
        return data;
    } else {
        throw new Error(data.error || 'Login failed');
    }
};

export const getCurrentUser = async () => {
    const response = await apiFetch('/api/me', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
        suppressError: true,
        suppressConnectivityError: true
    });

    return response.json();
};

export const uploadPhoto = async (imageData) => {
    const response = await apiFetch('/api/upload-photo', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: JSON.stringify({ image: imageData })
    });

    return response.json();
};

export const getUserMedia = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/user-media`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                'Content-Type': 'application/json',
                'ngrok-skip-browser-warning': 'yes',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch user media');
        }

        return response.json();
    } catch (error) {
        console.error('Error fetching user media:', error);
        throw error;
    }
};

export const uploadBundlePhoto = async (imageData) => {
    const response = await fetch(`${API_BASE_URL}/api/upload-bundle-photo`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'ngrok-skip-browser-warning': 'yes',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        },
        body: JSON.stringify({ image: imageData })
    });

    if (!response.ok) {
        throw new Error('Failed to upload photo');
    }

    return response.json();
};

export const deleteBundle = async (bundleId) => {
    const response = await fetch(`${API_BASE_URL}/api/delete-bundle`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'ngrok-skip-browser-warning': 'yes',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        },
        body: JSON.stringify({ bundle_id: bundleId })
    });

    if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Failed to delete bundle: ${errorMessage}`);
    }

    return response.json();
};

export const fetchMedia = async (mediaId) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/media/${mediaId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch media');
        }

        return response.blob();
    } catch (error) {
        console.error('Error fetching media:', error);
        throw error;
    }
};

export const getUserFeed = async (page = 1, limit = 100) => {
    try {
        const response = await fetch(
            `${API_BASE_URL}/api/feed?page=${page}&limit=${limit}`,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': 'yes',
                },
            }
        );

        if (!response.ok) {
            throw new Error('Failed to fetch user feed');
        }

        return response.json();
    } catch (error) {
        console.error('Error fetching user feed:', error);
        throw error;
    }
};

export const fetchUserBundles = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/bundles`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                'Content-Type': 'application/json',
                'ngrok-skip-browser-warning': 'yes',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch bundles');
        }

        return response.json();
    } catch (error) {
        console.error('Error fetching bundles:', error);
        throw error;
    }
};

export const updateProfilePicture = async (imageData) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/update-profile-picture`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                'Content-Type': 'application/json',
                'ngrok-skip-browser-warning': 'yes',
            },
            body: JSON.stringify({
                image: imageData
            })
        });

        if (!response.ok) {
            throw new Error('Failed to update profile picture');
        }

        return response.json();
    } catch (error) {
        console.error('Error updating profile picture:', error);
        throw error;
    }
};

export const getCreditHistory = async () => {
    try {
        const response = await fetch(
            `${API_BASE_URL}/api/credit-history?page=1&limit=100`,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': 'yes',
                },
            }
        );

        if (!response.ok) {
            throw new Error('Failed to fetch credit history');
        }

        const data = await response.json();
        return data.history;
    } catch (error) {
        console.error('Error fetching credit history:', error);
        throw error;
    }
};

export const getHasSubscription = async () => {
    const response = await fetch(`${API_BASE_URL}/api/has-subscription`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'ngrok-skip-browser-warning': 'yes',
        }
    });
    return response.json();
};

export const getBundleInfo = async (bundleId) => {
    const response = await fetch(`${API_BASE_URL}/api/bundle/${bundleId}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        }
    });

    if (!response.ok) {
        throw new Error('Failed to fetch bundle info');
    }

    return response.json();
};

export const createVideoForSpecificMedia = async (mediaId) => {
    try {
        const response = await apiFetch('/api/create-video-for-media', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            },
            body: JSON.stringify({ media_id: mediaId })
        });

        if (!response.ok) {
            throw new Error('Failed to create video for media');
        }

        return response.json();
    } catch (error) {
        return handleApiError(error, '/api/create-video-for-media');
    }
};

export const createPslGodBundleVideo = async (bundleId) => {
    try {
        const response = await apiFetch('/api/create-psl-god-bundle-video', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            },
            body: JSON.stringify({ bundle_id: bundleId })
        });

        if (!response.ok) {
            throw new Error('Failed to create PSL God bundle video');
        }

        return response.json();
    } catch (error) {
        return handleApiError(error, '/api/create-psl-god-bundle-video');
    }
};

