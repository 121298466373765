import { useState, useEffect } from "react";
import WebappContainer from "../components/common/WebappContainer";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import LooxerButton from "../components/common/Button";


const OneTimePaymentScreen = () => {
    const navigate = useNavigate();

    return (
        <WebappContainer style={{
            alignItems: 'center',
        }}>
            <div style={{
                background: `
linear-gradient(to bottom, 
rgb(0, 0, 0, 0) 0%,
rgb(0, 0, 0, 0) 5%,
rgb(0, 0, 0, 1) 50%,
rgb(0, 0, 0, 1) 100%
          )`,
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                zIndex: 0,
            }}>
            </div>

            <div style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
            }}>
                <IoClose color="rgb(150,150,150)" size="26px" onClick={() => navigate('/')} />
            </div>

            <video autoPlay
                loop
                muted
                playsInline

                style={{
                    width: '100px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    zIndex: 1,
                }}
            >
                <source src="/images/alpha_logo.webm" />
            </video>

            <div style={{
                color: 'white',
                fontSize: '30px',
                fontWeight: '600',
                fontStyle: 'italic',
                zIndex: 2,
            }}>
                SUPERCHARGE
            </div>
            <div>
                See your full potential.
            </div>

            <div style={{
                // backgroundColor:'red',

                flex: '1 0 0%',
                width: '100%',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',

                zIndex: 2,
            }}>
                <video autoPlay
                    loop
                    muted
                    playsInline

                    style={{
                        width: '60%',
                        alignSelf: 'center',
                        marginTop: '5px',
                        marginBottom: '20px',
                        outline: '3px white solid',
                        borderRadius:'10px',
                        // boxShadow: 'rgba(205, 31, 167, 0.56) 0px 22px 70px 4px',

                        // height: '100%',
                        // transform:'translateY(5px)',
                    }}
                    alt="demo_vid"
                >
                    <source src="/images/1227.mp4" />
                </video>
            </div>

            <div style={{
                width: '100%',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'stretch',
                zIndex: 2,
                gap: '10px',

                padding:'15px',
                boxSizing:'border-box',

                textAlign: 'center',
            }}>
                <div style={{
                    color: 'rgb(180,180,180)',
                    fontSize: '12px',
                }}>
                    The image processing costs us money, so we have to charge a small fee to keep the lights on. Hope you understand 🤝
                </div>
                
                <LooxerButton
                    active={true}
                    text="Start exploring your potential"
                    onClick={()=>{
                        window.open('https://looxer.gumroad.com/l/svsvt', '_blank');
                    }}
                />
                <div style={{
                    color:'white',
                    fontSize:'12px',
                    marginBottom:'20px',

                    display:'flex',
                    flexDirection:'row',
                    justifyContent:'center',
                    alignItems:'center',
                    flexWrap:'wrap',
                    gap:'7px'
                }}>
                One time payment of 4.99$ 
                <div style={{
                    backgroundColor:'white',
                    
                    width:'3px',
                    height: '3px',

                    borderRadius:'100%'
                }}/>
                10 credits
                <div style={{
                    backgroundColor:'white',
                    
                    width:'3px',
                    height: '3px',

                    borderRadius:'100%'
                }}/>
                Enough for 5 morphs/2 videos
                </div>
            </div>
        </WebappContainer>
    )
}

export default OneTimePaymentScreen;
